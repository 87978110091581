<template>
  <div>
    <b-card no-body>
      <!-- IMPORT RECORD -->
      <b-modal
        id="importModal"
        ref="importModal"
        title="Importation"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Importation de liste de données
          <hr />
        </h3>

        <b-form @submit.prevent="importer">
          <!-- FILE -->
          <b-form-group
            label="Pièce jointe"
            label-for="file"
          >
            <b-form-file
              @change="this.$coddyger.updateCurrFile"
              v-model="formdata.file"
              id="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              placeholder="Charger un fichier ou glissez-le ici..."
              drop-placeholder="Glisser un fichier ici..."
              size="lg"
              no-drop
              required
            />
            <a
              class="text text-danger"
              v-if="formdata.file"
              @click="emptyFileInput"
              ><small>Retirer fichier</small></a
            >
          </b-form-group>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('import')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Importer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      
      <b-row class="match-height">
        <b-col md="12">
          <b-card>
            
            <div>
              <b-row>
                <b-col md="6">
                  <b-card-text v-if="!this.postData"> Enregister un nouveau centre </b-card-text>
                  <b-card-text v-if="this.postData"> Modification de centre <br />{{this.postData.slug}} ({{this.postData.name}}) </b-card-text>
                </b-col>
                <b-col md="6">
                  <!-- IMPORT -->
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button variant="outline-primary"  @click="openImportDialog">
                      Importer
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              
              <hr />
              <small class="text-danger">* Champs obligatoire</small>
              
            </div>
            <b-form @submit.prevent="save" class="mt-2">
              <b-row>
                <b-col md="6">
                  <!-- CATEGORY -->
                  <b-form-group label="* Catégorie" label-for="category">
                    <v-select
                      id="category"
                      label="text"
                      value="key"
                      v-model="formdata.category"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      placeholder="Sélectionner une categorie"
                      :options="formdata.categories"
                    />
                  </b-form-group>
                  <!-- NAME -->
                  <b-form-group
                    label="* Nom du centre"
                    label-for="name"
                  >
                    <b-form-input
                      v-model="formdata.name"
                      id="name"
                      placeholder="Nom"
                      required
                    />
                  </b-form-group>
                  <b-row>
                    <b-col md="6">
                      <!-- OWNER -->
                      <b-form-group
                        label="* Responsable du centre"
                        label-for="owner"
                      >
                        <b-form-input
                          v-model="formdata.owner"
                          id="owner"
                          placeholder="Responsable"
                          required
                        />
                      </b-form-group>
                    </b-col> 
                    <b-col md="6">
                      <!-- Contact -->
                      <b-form-group
                        label="* Contact du centre"
                        label-for="contact"
                      >
                        <b-form-input
                          v-model="formdata.contact"
                          id="contact"
                          placeholder="Contacts"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <!-- EMAIL -->
                      <b-form-group
                        label="* Adresse e-mail"
                        label-for="email"
                      >
                        <b-form-input
                          v-model="formdata.email"
                          id="email"
                          placeholder="Adresse e-mail"
                          required
                        />
                      </b-form-group>
                    </b-col> 
                    <b-col md="6">
                      <!-- ADDRESS -->
                      <b-form-group
                        label="* Adresse de localisation"
                        label-for="address"
                      >
                        <b-form-input
                          v-model="formdata.address"
                          id="address"
                          placeholder="Adresse"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row>
                    <b-col md="12">
                      <!-- COMMUNE -->
                      <b-form-group
                        label="* Commune"
                        label-for="commune"
                      >
                        <b-form-input
                          v-model="formdata.commune"
                          id="commune"
                          placeholder="Commune"
                          required
                        />
                      </b-form-group>
                    </b-col> 
                    <b-col md="6">
                      <!-- LATITUDE -->
                      <b-form-group
                        label="Latitude"
                        label-for="latitude"
                      >
                        <b-form-input
                          v-model="formdata.latitude"
                          id="latitude"
                          placeholder="Latitude"
                          
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <!-- LONGITUDE -->
                      <b-form-group
                        label="Longitude"
                        label-for="longitude"
                      >
                        <b-form-input
                          v-model="formdata.longitude"
                          id="longitude"
                          placeholder="Longitude"
                          
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="4">
                      <!-- CNPS -->
                      <b-form-group
                        label="Affiliation CNPS"
                        label-for="cnps"
                      >
                        <v-select
                          id="cnps"
                          label="text"
                          value="key"
                          v-model="formdata.cnps"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Sélectionner"
                          :options="formdata.cnps_choices"
                        />
                      </b-form-group>
                    </b-col> 
                    <b-col md="4">
                      <!-- GARDE -->
                      <b-form-group
                        label="Garde"
                        label-for="garde"
                      >
                        <v-select
                          id="garde"
                          label="text"
                          value="key"
                          v-model="formdata.garde"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Sélectionner"
                          :options="formdata.garde_choices"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <!-- MEMBRE -->
                      <b-form-group
                        label="Membre du réseau"
                        label-for="membre"
                      >
                        <v-select
                          id="membre"
                          label="text"
                          value="key"
                          v-model="formdata.membre"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Sélectionner"
                          :options="formdata.member_choices"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <hr />
              <!-- submit and reset -->
              <b-row>
                <b-col>
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="Options"
                    variant="primary"
                  >
                    <b-dropdown-item v-if="this.$route.name !== 'apps-medical-centre-save'" :to="{name: 'apps-medical-centre-save'}">Nouveau</b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item :to="{name: 'apps-medical-pharmacie-garde'}">Pharmacies de garde</b-dropdown-item>
                    <b-dropdown-item :to="{name: 'apps-medical-pharmacie-agree'}">Pharmacies</b-dropdown-item>
                    <b-dropdown-item :to="{name: 'apps-medical-centre-agree'}">Cliniques agrées CNPS</b-dropdown-item>
                    <b-dropdown-item :to="{name: 'apps-medical-centre'}">Cliniques et hôpitaux</b-dropdown-item>
                    <b-dropdown-item :to="{name: 'apps-medical-centre-infirmerie'}">Infirmeries</b-dropdown-item>
                  </b-dropdown>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    @click="resetValues()"
                    class="ml-1"
                  >
                    Réinitialiser
                  </b-button>
                  
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="ml-1"
                    style="float: right"
                    :disabled="disableButtonOnLoading"
                  >
                    <span v-if="!isLoading">Enregistrer</span>
                    <span v-if="isLoading">
                      <b-spinner small type="grow"></b-spinner>
                      Chargement...
                    </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BTooltip,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BFormTags,
  BListGroup,
  BListGroupItem,BDropdown, BDropdownItem, BDropdownDivider
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import ModuleCentre from "@/store/modules/medical/centre/centre.module";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import * as payloads from './payloads'

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BFormTags,
    BListGroup,
    BListGroupItem,
    BDropdown, 
    BDropdownItem,
    BDropdownDivider,

    vSelect,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  data() {
    return {
      postData: null,
      isLoading: false,
      singleData: [], // For single view mode
      locale: "fr",
      isModalActive: false,
      modalData: [],
      // Data NEW/EDIT Modal
      formdata: {
        slug: null,
        //-FORM-PROPERTIES-
        category: "",
        name: "",
        owner: "",
        contact: "",
        email: "",
        address: "",

        commune: "",
        latitude: "",
        longitude: "",
        cnps: "",
        garde: "",
        membre: "",

        categories: payloads.category,
        member_choices: payloads.member_choices,
        cnps_choices: payloads.cnps_choices,
        garde_choices: payloads.garde_choices,
        options: {
          date: {
            date: true,
            delimiter: "-",
            datePattern: ["d", "m", "Y"],
          },
        },
      },
      // MODAL TOOLS
      modaldata: {
        data: [],
        isModalActive: false,
      }
    };
  },
  created() {
    if (!ModuleCentre.isRegistered) {
      this.$store.registerModule("ModuleCentre", ModuleCentre);
      ModuleCentre.isRegistered = true;
    }

    
    if(this.$route.name === 'apps-medical-centre-edit') {
      let slug = this.buildCurrentSlug();
      this.$store
      .dispatch("ModuleCentre/fetchItem", slug)
      .then((res) => {
        this.postData = res;
        this.formdata.slug = res.slug;
        
        this.formdata.category = {
          key: res.type,
          text: this.$coddyger.capitalizeFirstLetter(res.type),
        }
        this.formdata.name = res.name
        this.formdata.owner = res.owner
        this.formdata.contact = res.contact
        this.formdata.email = res.email
        this.formdata.address = res.address

        this.formdata.commune = res.commune
        this.formdata.latitude = (res.geoloc ? res.geoloc[0] : '')
        this.formdata.longitude = (res.geoloc ? res.geoloc[1] : '')
        this.formdata.cnps = {
          key: res.cnps,
          text: (res.cnps ? 'Oui' : 'Non')
        }
        this.formdata.garde = {
          key: res.garde,
          text: (res.garde ? 'De garde' : 'Non')
        }
        this.formdata.membre = {
          key: res.member,
          text: (res.member ? 'Oui' : 'Non')
        }
      })
      .catch((res) => {
        const { status, message, data } = res;
        //console.log(message)
      });
    }
    
  },
  computed: {
    buildData() {
      const data = this.$store.state.ModuleCentre.data;
      this.tabledata.totalRows = data === undefined ? 0 : data.length;

      return data === undefined ? [] : data;
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isModalActive() {
      console.log('enterring watch')
      this.resetValues();
    },
  },

  methods: {
    buildCurrentPath() {
      let path = this.$router.history.current.path;
      let getLastItem = (thePath) =>
        thePath.substring(thePath.lastIndexOf("/") + 1);
      path = getLastItem(path);

      return {
        path: path,
        route: `apps-post-${path}`
      };
    },
    buildCurrentSlug() {
      let path = this.$router.history.current.path;
      let getLastItem = (thePath) =>
        thePath.substring(thePath.lastIndexOf("/") + 1);
      path = getLastItem(path);

      return path;
    },
    emptyFileInput() {
      this.formdata.file = null;
    },
    closeModal(object) {
      if (object === "save") {
        this.$refs["setDataModal"].hide();
        this.resetValues();
      } else if (object === "edit") {
        this.$refs["editModal"].hide();
        this.resetValues();
      } else {
        this.$refs["importModal"].hide();
      }
    },
    openSaveDialog() {
      this.$refs["setDataModal"].show();
    },
    openImportDialog() {
      this.$refs["importModal"].show();
    },
    swalError(title, message, type) {
      this.$swal({
        title: title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openDialog(data) {
      this.$refs.singleDataModal.show();
      this.singleData = data;
    },
    revertUrl() {
      console.log(this.buildCurrentPath().route)
      this.$router.replace({ name: this.buildCurrentPath().route });
    },
    // END ---
    // MODAL FORM TOOL --------------
    resetValues() {
      this.formdata.slug = null;

      this.formdata.category = []
      this.formdata.name = ""
      this.formdata.owner = ""
      this.formdata.contact = ""
      this.formdata.email = ""
      this.formdata.address = ""

      this.formdata.commune = ""
      this.formdata.latitude = ""
      this.formdata.longitude = ""
      this.formdata.cnps = {
        key: false,
        text: 'Non'
      }
      this.formdata.garde = {
        key: false,
        text: 'Non'
      }
      this.formdata.membre = {
        key: true,
        text: 'Oui'
      }
    },
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.title === "") {
        this.swalError("Erreur", this.buildRequiredMessage("titre"), "error");
      } else if (this.formdata.category === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Categorie"),
          "error"
        );
      } else {
        let global = {
          slug: this.formdata.slug,
          type: this.formdata.category.key,
          name: this.formdata.name,
          owner: this.formdata.owner,
          contact: this.formdata.contact,
          email: this.formdata.email,
          address: this.formdata.address,

          commune: this.formdata.commune,
          geoloc: [this.formdata.latitude, this.formdata.longitude],
          cnps: this.formdata.cnps.key,
          garde: this.formdata.garde.key,
          member: this.formdata.membre.key
        };

        this.isLoading = true;

        if (this.formdata.slug !== null && this.formdata.slug !== "") {
          this.$store
            .dispatch("ModuleCentre/edit", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Modification effectué avec succès!`,
                },
              });
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
        } else {
          this.$store
            .dispatch("ModuleCentre/save", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Enregistrement modifié avec succès!`,
                },
              });
              this.resetValues();
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
        }
      }
    },
    importer(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.file === "" || this.formdata.file === null) {
        this.swalError(
          "Erreur",
          "Veuillez sélectionner le fichier à importer",
          "error"
        );
      } else {
        let global = {};

        let formData = new FormData();

        formData.append("file", this.formdata.file);
        
        this.isLoading = true;
        this.$store
          .dispatch("ModuleCentre/importItem", formData)
          .then((doc) => {
            this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Fichier importé avec succès! ${doc.success} lignes importées`,
                },
              });
            
            this.formdata.file = null;
            this.isLoading = false;
            //this.$router.go()
          })
          .catch((error) => {
            let exist = error.exist;
            let failed = error.failed;
            let log = error.errorLog;

            let message = []
            for(let x in log) {
              message.push(log[x].message)
            }

            if (failed >= 1) {
              this.swalError("Attention", 'Importation réussie avec ' + failed + ' erreurs - ' + message.join(','), "error");
            } else {
              this.swalError("Erreur", message, "error");
            }

            console.log(log)
            
            this.isLoading = false;
          });
      }
    },
    /* ---------------------
    | RECORD REMOVAL HANDLER
    */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { pid: id }),
      });
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Voulez-vous supprimer cet enregistrement ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-danger",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteData();
          } else {
            this.revertUrl();
          }
        });
    },
    deleteData() {
      let id = this.$route.query.pid;
      if (id !== "" || id !== null) {
        this.$store
          .dispatch("ModuleCentre/remove", {
            item: id,
            path: this.buildCurrentPath().path,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Action Réussie`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Enregistrement supprimé avec succès!`,
              },
            });
            this.revertUrl();
          })
          .catch((error) => {
            if (typeof error === "object") {
              for (let err in error) {
                this.swalError("Erreur", err, "error");
              }
            } else {
              this.$vs.notify({
                title: "Erreur",
                text: error,
                color: "danger",
                icon: "error",
              });
            }
          });
      } else {
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`;
    },
    buildEditModal(data) {
      this.modaldata.data = data;
      this.modaldata.isModalActive = true;

      if (!data) return;
      if (Object.entries(data).length === 0) {
        this.resetValues();
      } else {
        const { slug, title, category, categoryName, content } = JSON.parse(
          JSON.stringify(data)
        );
        
        this.formdata.slug = slug;
        this.formdata.title = title;
        this.formdata.category = {
          key: category,
          text: categoryName
        };
        this.formdata.content = content;
      }

      this.$refs['setDataModal'].show()
    },
    back() {
      this.$router.go(-1)
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
      appDesc,
    };
  },
};
</script>
